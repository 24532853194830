export const splitValueUnit=function(valueUnit){
  let res={
    value:null,
    unit:null
  }
  res.value=parseFloat(valueUnit);
  res.unit=valueUnit.replace(res.value,'').replace(/\\./, '');
  return res;
}

const twoDigits=function(value) {
  return value < 10 ? '0' + value : value;
}

/**
 * 日期转化为字符串：yyyy-MM-dd HH:mm:ss
 * @param {*} date 
 * @returns 
 */
export const formatDate=function(date) {
  let year = date.getFullYear();
  let month = twoDigits(date.getMonth() + 1); // 月份是从0开始的
  let day = twoDigits(date.getDate());
  let hour = twoDigits(date.getHours());
  let minute = twoDigits(date.getMinutes());
  let second = twoDigits(date.getSeconds());
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}


// export const HexToRGB=function(color){
// 	let newColor = 'rgba(';
// 	//判断是四位还是八位
// 	if(color.length === 5){
// 		let arry = [];
// 		let opacity=null;
// 		for(let i = 1;i < color.length;i++){
// 			if(i===color.length-1){
// 				opacity=parseInt("0x" + color[i] + color[i]);
// 				break;
// 			}
// 			arry.push(parseInt("0x" + color[i] + color[i]));
// 		}
// 		arry.forEach(function(item){
// 			newColor += item + ', ';
// 		});
// 		newColor += opacity/255.0 + ')';
// 		return newColor;
// 	}else{
// 		let arry = [];
// 		let opacity=null;
// 		for(let i = 1;i < color.length;i += 2){
// 			if(i===color.length-2){
// 				opacity=parseInt("0x" + color.slice(i,i+2));
// 				break;
// 			}
// 			arry.push(parseInt("0x" + color.slice(i,i+2)));
// 		}
 
// 		arry.forEach(function(item){
// 			newColor += item + ', ';
// 		});
// 		newColor += opacity/255.0 + ')';
// 		return newColor;
// 	}
// }

// export const checkRGBA=function(rgba){
// 	let str = rgba.slice(5,rgba.length - 1),
// 	arry = str.split(','),
// 	status = true,
// 	reg = /^rgba\(\d{1,3}(\,\s{0,1}\d{1,3}){2}\,\s{0,1}(0|(0(\.\d{1,2}))|1)\)$/;
// 	arry.forEach(function(item,index){
// 		if(index == arry.length - 1){
// 			if(Number(item.trim()) < 0 || Number(item.trim()) > 1){
// 				status = false;
// 			}
// 		}else{
// 			if(Number(item.trim()) < 0 || Number(item.trim()) > 255){
// 				status = false;
// 			}
// 		}
// 	});
	
// 	if(reg.test(rgba) && status){
// 		return true;
// 	}else{
// 		return false;
// 	}
// }
